export type Attribute = {
  name: string
  slug: string
  iconSrc?: string
  relatedBuckets?: string[]
  relatedAttributes?: string[]
}

export type AttributeRelationships = {
  buckets: {
    [key: string]: Attribute[]
  }
  floating: Attribute[] | null
}

export type AttributeMap = {
  [key: string]: Attribute
}

export const SCREEN_MOBILE = "screen.mobile"
export const SCREEN_DESKTOP = "screen.desktop"
export const SCREEN_HEADSET = "screen.headset"

export const SCREENS = [SCREEN_MOBILE, SCREEN_DESKTOP, SCREEN_HEADSET] as const
export const PLATFORM_PWA = "platform.pwa"
export const PLATFORM_IOS = "platform.ios"
export const PLATFORM_ANDROID = "platform.android"
export const PLATFORM_MACOS = "platform.macos"
export const PLATFORM_WEB_APP = "platform.web-app"

export const PLATFORMS = [
  PLATFORM_PWA,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_MACOS,
  PLATFORM_WEB_APP,
] as const

export const DEFAULT_ATTRIBUTE_RELATIONSHIPS: AttributeRelationships = {
  buckets: {
    Category: [
      {
        slug: "category.ai",
        name: "AI",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.business",
        name: "Business",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.crypto",
        name: "Crypto",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "category.developer-tools",
        name: "Developer Tools",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.education",
        name: "Education",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.entertainment",
        name: "Entertainment",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.finance",
        name: "Finance",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.food-drink",
        name: "Food & Drink",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.games",
        name: "Games",
        relatedBuckets: ["gaming"],
        relatedAttributes: [],
      },
      {
        slug: "category.graphics-design",
        name: "Graphics & Design",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.health-fitness",
        name: "Health & Fitness",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.kids",
        name: "Kids",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.lifestyle",
        name: "Lifestyle",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.medical",
        name: "Medical",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.music",
        name: "Music",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.navigation",
        name: "Navigation",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.news",
        name: "News",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.photo-video",
        name: "Photo & Video",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.productivity",
        name: "Productivity",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.reference",
        name: "Reference",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.shopping",
        name: "Shopping",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.social",
        name: "Social",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.sports",
        name: "Sports",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.travel",
        name: "Travel",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.utilities",
        name: "Utilities",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "category.weather",
        name: "Weather",
        relatedBuckets: [],
        relatedAttributes: [],
      },
    ],
    Crypto: [
      {
        slug: "crypto.analytics",
        name: "Analytics",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.exchanges",
        name: "Exchanges",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.ethereum",
        name: "Ethereum",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.layerzero",
        name: "Layerzero",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.chia",
        name: "Chia",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.btc",
        name: "BTC",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.solana",
        name: "Solana",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.polygon",
        name: "Polygon",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.bnb",
        name: "BNB",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.avalanche",
        name: "Avalanche",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.arbitrum",
        name: "Arbitrum",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.xrp",
        name: "XRP",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.optimism",
        name: "Optimism",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.cosmos",
        name: "Cosmos",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.polkadot",
        name: "Polkadot",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
      {
        slug: "crypto.base",
        name: "Base",
        relatedBuckets: ["crypto"],
        relatedAttributes: [],
      },
    ],
    Gaming: [
      {
        slug: "games.strategy",
        name: "Strategy",
        relatedBuckets: ["gaming"],
        relatedAttributes: [],
      },
      {
        slug: "games.boardgame",
        name: "Boardgame",
        relatedBuckets: ["gaming"],
        relatedAttributes: [],
      },
      {
        slug: "games.multiplayer",
        name: "Multiplayer",
        relatedBuckets: ["gaming"],
        relatedAttributes: [],
      },
      {
        slug: "games.singleplayer",
        name: "Singleplayer",
        relatedBuckets: ["gaming"],
        relatedAttributes: [],
      },
    ],
    "Device Type": [
      {
        slug: "screen.mobile",
        name: "Mobile",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "screen.desktop",
        name: "Desktop",
        relatedBuckets: [],
        relatedAttributes: [],
      },
      {
        slug: "screen.headset",
        name: "Headset",
        relatedBuckets: [],
        relatedAttributes: [],
      },
    ],
  },
  floating: null,
}
