import { first } from "lodash-es"
import {
  AppAllContent,
  AppAllContentMerged,
  FormFactor,
  Icon,
  ListingScreen,
  SCREEN_DESKTOP,
  SCREEN_HEADSET,
  SCREEN_MOBILE,
  PLATFORM_PWA,
  PreviewType,
  Review,
  ListingPlatform,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  PLATFORM_MACOS,
  PLATFORM_WEB_APP,
} from "@store-platform/types"
import { SVGProps } from "react"
import {
  SvgAndroid,
  SvgDesktop,
  SvgHeadset,
  SvgIos,
  SvgMacOs,
  SvgMobile,
  SvgPwa,
  SvgWebapp,
} from "@store-platform/ui/icons"

type RatingCount = {
  [key: number]: number
}

export const mapReviewsToCounts = (reviews: Review[]) => {
  if (reviews.length === 0) {
    return [
      { rating: "1", count: 0 },
      { rating: "2", count: 0 },
      { rating: "3", count: 0 },
      { rating: "4", count: 0 },
      { rating: "5", count: 0 },
    ]
  }

  const ratings = reviews.map((r) => r.rating)

  const rr = ratings.reduce(
    (acc: RatingCount, rating) => {
      acc[rating]++
      return acc
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  )

  const counts = Object.entries(rr).map(([rating, count]) => ({
    rating: rating.toString(),
    count,
  }))

  return counts
}

export const parseAuditDetails = (src: string) => {
  if (!src) return {}
  const [appId, end] = src?.split("/")?.slice(-2) ?? []
  const unixTimestamp = first(end?.split(".")) || ""
  return { appId, unixTimestamp }
}

export const getAuditTimeFromS3Url = (src?: string): Date | undefined => {
  if (!src) return undefined

  const match = src.match(/(\d+)\.json$/)
  return match ? new Date(parseInt(match[1])) : undefined
}

export const isPwa = (attributes?: string[]): boolean =>
  (attributes || []).some((attr) => attr === PLATFORM_PWA)

export const getIconDimensions = (
  icon: Icon,
): { width: number; height: number } => {
  const match = icon.sizes.match(/^(\d+)x(\d+)$/)
  if (!match) return { width: 0, height: 0 }

  const [, width, height] = match
  return { width: parseInt(width), height: parseInt(height) }
}

/**
 * Get the best icon according to this priority list (goes to the next one in case of a tie):
 * 1. maskable over non-maskable
 * 2. highest `width` first
 * 3. `src` alphabetically
 */
export const getBestIcon = (icons?: Icon[]): Icon | undefined => {
  if (!icons || icons.length === 0) return undefined

  const sorted = [...icons].sort((a, b) => {
    const aDims = getIconDimensions(a)
    const bDims = getIconDimensions(b)

    if (a.purpose === b.purpose)
      return bDims.width - aDims.width || a.src.localeCompare(b.src)

    return a.purpose === "maskable" ? -1 : 1
  })

  return first(sorted)
}

export const mergeAppAllContent = (
  allContent: AppAllContent,
): AppAllContentMerged =>
  Object.assign(
    {},
    {
      ...allContent.manifest?.content,
      mobileVerticalPreviews: allContent.manifest?.screenshots,
      icons: allContent.manifest?.icons,
    },
    {
      ...allContent.override?.patch,
      ...allContent.globalOverride?.patch,
      links: allContent.override?.links,
      mobileVerticalPreviews: allContent.override?.mobileVerticalPreviews,
      desktopPreviews: allContent.override?.desktopPreviews,
      headsetPreviews: allContent.override?.headsetPreviews,
    },
    {
      icon:
        getBestIcon(allContent.override?.patch?.icons) ||
        getBestIcon(allContent.manifest?.icons),
      maskableIcon:
        getBestIcon(allContent.override?.patch?.icons) ||
        getBestIcon(allContent.manifest?.icons),
    },
  )

export const screenIcons: {
  id: ListingScreen
  Icon: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
}[] = [
  { id: SCREEN_MOBILE, Icon: SvgMobile },
  { id: SCREEN_DESKTOP, Icon: SvgDesktop },
  { id: SCREEN_HEADSET, Icon: SvgHeadset },
]

export const platformIcons: {
  id: ListingPlatform
  Icon: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
}[] = [
  { id: PLATFORM_IOS, Icon: SvgIos },
  { id: PLATFORM_ANDROID, Icon: SvgAndroid },
  { id: PLATFORM_MACOS, Icon: SvgMacOs },
  { id: PLATFORM_WEB_APP, Icon: SvgWebapp },
]

export const previewTypeToFormFactor = (type: PreviewType): FormFactor => {
  switch (type) {
    case "desktop_previews":
      return "wide"
    case "mobile_vertical_previews":
      return "narrow"
    case "headset_previews":
      return "headset"
  }
}
