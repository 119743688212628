"use client"

import { AnalyticsTracker, AnalyticsUser } from "@store-platform/backend/common"

const backendApiRootUrl = process.env["NEXT_PUBLIC_BACKEND_API_ROOT_URL"]
const siteId = process.env["NEXT_PUBLIC_SITE_ID"] || ""

function getHeaders(token: string | null): HeadersInit {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "X-Site-Id": siteId,
  }
  if (token) headers["Authorization"] = `Bearer ${token}`
  return headers
}

export async function trackClientEvent(
  user: AnalyticsUser,
  tracker: AnalyticsTracker,
) {
  if (!backendApiRootUrl) return

  const body = JSON.stringify({
    s: siteId,
    o: tracker.obj,
    a: tracker.action,
    p: tracker.properties,
    an: user.anonymousId,
    us: user.id,
  })

  navigator.sendBeacon
    ? navigator.sendBeacon(
        `${backendApiRootUrl}/a/t`,
        new Blob([body], {
          type: "application/json",
        }),
      )
    : fetch(`${backendApiRootUrl}/a/t`, {
        method: "POST",
        headers: getHeaders(user.token),
        body: body,
        keepalive: true,
      }).catch()
}

export async function trackPageView(
  user: AnalyticsUser,
  pathname: string,
  search?: URLSearchParams,
) {
  if (!backendApiRootUrl) return

  const body = JSON.stringify({
    p: pathname,
    r: document.referrer,
    s: search?.size ? `?${search?.toString()}` : undefined,
    t: document.title,
    u: window.location.href,
    an: user.anonymousId,
    us: user.id,
  })

  navigator.sendBeacon
    ? navigator.sendBeacon(
        `${backendApiRootUrl}/a/p`,
        new Blob([body], {
          type: "application/json",
        }),
      )
    : fetch(`${backendApiRootUrl}/a/p`, {
        method: "POST",
        headers: getHeaders(user.token),
        body: body,
        keepalive: true,
      }).catch()
}

export async function trackClientIdentify(user: AnalyticsUser) {
  const body = JSON.stringify({
    an: user.anonymousId,
    us: user.id,
  })

  navigator.sendBeacon
    ? navigator.sendBeacon(`${backendApiRootUrl}/a/i`, body)
    : fetch(`${backendApiRootUrl}/a/i`, {
        method: "POST",
        headers: getHeaders(user.token),
        body: body,
      }).catch()
}
